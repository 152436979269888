import React from 'react';
import NavBar from "../NavBar";
import ContactUsForComponent from '../ContactUsForComponent';

import style from '../module/navbar.module.css'

const individualPrices = [
  { range: "Upto 5 MB", price: "Free" },
  { range: "Upto 10 MB", price: "99/-" },
  { range: "Upto 20 MB", price: "199/-" },
  { range: "Upto 30 MB", price: "299/-" },
  { range: "Upto 50 MB", price: "499/-" },
  { range: "Upto 1 GB", price: "999/-" },
  { range: "Upto 2 GB", price: "1799/-" },
  { range: "Upto 3 GB", price: "2599/-" },
  { range: "Upto 5 GB", price: "3999/-" },
  { range: "Upto 10 GB", price: "5999/-" },
  { range: "Upto 15 GB", price: "9999/-" },
  { range: "Upto 20 GB", price: "12999/-" },
  { range: "Upto 25 GB", price: "15999/-" },
  { range: "Upto 30 GB", price: "19999/-" },
  { range: "Upto 35 GB", price: "22999/-" },
  { range: "Upto 40 GB", price: "25999/-" },
  { range: "Upto 45 GB", price: "29999/-" },
  { range: "Upto 50 GB", price: "34999/-" },
  { range: "Upto 60 GB", price: "44999/-" },
  { range: "Upto 70 GB", price: "54999/-" },
  { range: "Upto 80 GB", price: "64999/-" },
  { range: "Upto 90 GB", price: "74999/-" },
  { range: "Upto 100 GB", price: "79999/-" },
];

const PricingPage = () => {
  // Define the spring animation

  return (
    <>
      <NavBar />
      <div className="text-center font-raleway flex flex-col p-5 justify-center items-center shadow-[0_5px_15px_rgba(0,0,0,0.35)] m-4 rounded-[11px] w-full">
        <div className={`bg-yellow-500 text-white p-4 sticky top-2 shadow-lg w-[80%] relative overflow-hidden ${style.slideDown}`}>
          <h3 className="text-center">
            Annual renewal price <span className='text-[25px] font-bold'>99</span> rs for ONE GB
          </h3>
        </div>

        <div className="text-center p-5 w-[80%]">
          <h1 className="text-3xl text-blue-800 mb-6">Pricing Plans</h1>

          <div className="max-w-4xl mx-auto w-[80%]">
            <h2 className="text-2xl text-blue-700 mb-4">Individual</h2>
            <table className="min-w-full border-collapse bg-blue-50 mx-auto w-[80%]">
              <thead>
                <tr className="bg-blue-100 text-blue-900">
                  <th className="p-3 border border-blue-200">Storage</th>
                  <th className="p-3 border border-blue-200">Price</th>
                </tr>
              </thead>
              <tbody>
                {individualPrices.map((item, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-blue-100' : 'bg-blue-50'}>
                    <td className="p-3 border border-blue-200">{item.range}</td>
                    <td className="p-3 border border-blue-200">{item.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ContactUsForComponent/>
    </>
  );
}

export default PricingPage;
